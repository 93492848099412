.main__container {
  width: 100%;
  height: 700px;
  background-size: cover;
  background-color: rgb(10, 10, 10);
}

.main__box {
  height: 100%;
  display: flex;
  justify-content: center;
}

.profile__img {
  align-self: self-end;
  width: 580px;
  z-index: 2;
}

.profile__title {
  z-index: 0;
  font-size: 50px;
  width: 500px;
  height: 50px;
  position: absolute;
  top: 18%;
  text-align: center;
  color: white;
  transition: transform 1s ease;
}

.profile__title span {
  color: #fd853a;
}

.hello__text {
  font-size: 20px;
}

.profile__vector--1 {
  position: absolute;
  top: 300%;
  left: 4%;
  width: 50px;
}

.profile__vector--2 {
  position: absolute;
  top: -20%;
  right: 40%;
  width: 25px;
  transform: rotate(180deg);
}

.intro__title {
  font-size: 20px;
  color: #fd853a;
}

.intro__content {
  font-size: 14px;
}

.elements {
  position: absolute;
  transition: transform 1s ease;
  transition: 1s ease;
  font-size: 40px;
  color: #fd853a;
}

.marketing__text {
  top: 50%;
  left: 10%;
}

.it__text {
  top: 50%;
  right: 10%;
}
