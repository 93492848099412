.contact__container {
  width: 100%;
  height: 450px;
  background-color: rgb(10, 10, 10);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 100px 0 100px;
}

.contact__box {
  width: 100%;
  height: 600px;
}

.contact__header {
  width: 100%;
  border-bottom: 1px solid #475467;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
}

.contact__header--text {
  font-size: xx-large;
  font-weight: 700;
}

.contact__header--text span {
  color: #fd853a;
}
.contact__header__button {
  width: 120px;
  height: 50px;
  border-radius: 30px;
  background-color: #fd853a;
  color: black;
}

.contact__info {
  width: 100%;
  padding: 30px 0px;
  border-bottom: 1px solid #475467;
  color: white;
  display: flex;
  gap: 20px;
  align-items: start;
  justify-content: space-between;
}

.contact__nav {
  display: flex;
  flex-direction: column;
}

.info__box {
  width: 400px;
}

.nav__box a {
  text-decoration: none;
  color: white;
}

.contact__contact {
}

.contact__title {
  color: #fd853a;
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact__title img {
  width: 40px;
}

.telegram__input {
  gap: 10px;
  display: flex;
  align-items: center;
}

.telegram__input input {
  border-radius: 15px;
  padding: 5px;
  border: none;
}

.telegram__input img {
  width: 35px;
}
.copyright {
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
}
