.experience__container {
  width: 100%;
  color: white;
  background-color: rgb(10, 10, 10);
}

.experience__title {
  width: 100%;
  text-align: center;
  font-size: 50px;
  padding: 70px 0;
}

.experience__title span {
  color: #fd853a;
}

/* .experience__main {
  width: 100%;
  height: 450px;
  background-color: rgb(5, 5, 5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 80px 30px 80px;
} */

.experience__main {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px; /* Khoảng cách giữa các box */
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-bottom: 5%;
  place-items: center; /* Căn giữa các box theo cả hai chiều trong grid cells */
  transition: 1s ease;
}
.name__date {
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  width: 350px;
  height: 100%;
}

.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  width: 350px;
  height: 100%;
}

/* Job */
.job__box {
  width: 280px;
  height: 400px;
  border: 3px solid #fd853a;
  border-radius: 50px;
  padding: 30px;
  transition: 2s ease;
}

.job__img--box {
  width: 100%;
  text-align: center;
}

.job__img {
  width: 200px;
  border-radius: 20px;
}

.job__name {
  margin-top: 12px;
  width: 100%;
  text-align: center;
  color: #fd853a;
  font-size: 20px;
  font-weight: 600;
}

.job__date {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.job__btn--box {
  width: 100%;
  text-align: center;
  margin-top: 10%;
}

.job__btn {
  border: #fd853a 1px solid;
  border-radius: 10px;
  font-weight: 600;
  background-color: #fd853a;
}

.mySwiper {
  width: 500px;
  height: 320px;
}

.mySwiper .swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  border: 1px solid rgb(207, 207, 207);
  background-image: url("../Asset/modern-background-with-lines.jpg");
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  width: 80px;
  border-radius: 50%;
  border: 3px solid black;
}
