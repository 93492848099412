.journey__container {
  width: 100%;
  height: 700px;
  background-color: #000000;
}

.journey__box {
  width: 100%;
  height: 100%;
  background-color: white;
}

.skill__title {
}

.slider {
  background-color: #ffffff;
  border: #fd853a 4px inset;
}

.slide {
  margin: 15px 100px;
}

.slide img {
  height: 45px;
}

.journey__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.journey__box {
  width: 100%;
}

.para_Swiper {
  width: 100%;
  height: 100%;
  background: #000;
}

.para_Swiper .swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
}

.para_Swiper .parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 140%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.para_Swiper .swiper-slide .title {
  font-size: 41px;
  font-weight: 300;
}

.para_Swiper .swiper-slide .subtitle {
  font-size: 21px;
}

.para_Swiper .swiper-slide .text {
  font-size: 14px;
  max-width: 400px;
  line-height: 1.3;
}
